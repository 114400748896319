<template>
  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <b-card-actions
          ref="loadingCard"
          no-actions
      >

        <b-table
            ref="refCallbackListTable"
            class="position-relative"
            :items="callbackLogs"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
        >

          <!-- Column: Start at -->
          <template #cell(start_at)="data">
            {{ data.item.start_at | formatDate }}
          </template>

          <!-- Column: End at -->
          <template #cell(end_at)="data">
            {{ data.item.end_at | formatDate }}
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
                v-if="data.item.status === 'active'"
                pill
                variant="danger"
            >
              Active
            </b-badge>

            <b-badge
                v-if="data.item.status === 'succeeded'"
                pill
                variant="success"
            >
              Success
            </b-badge>

            <b-badge
                v-if="data.item.status === 'failed'"
                pill
                variant="danger"
            >
              Failed
            </b-badge>
          </template>

        </b-table>
      </b-card-actions>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, VBTooltip, BBadge,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BCardActions,
    BTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['callbackLogs'],
  data() {
    return {
      tableColumns: [
        { key: 'start_at', label: 'Start time', sortable: true },
        { key: 'end_at', label: 'End time', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'response', label: 'Response', sortable: true },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
