<template>
  <b-modal
      id="callback-log-modal"
      title="View log"
      size="xl"
      ok-only
      ok-title="Ok"
      scrollable
  >
    <b-card-text>
      <callback-log-list :callback-logs="this.logs" />
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCardText,
} from 'bootstrap-vue'
import CallbackLogList from '@/views/shipments/cards/CallbackLogList.vue'

export default {
  components: {
    BCardText,
    BModal,
    CallbackLogList,
  },
  props: ['logs'],
}
</script>
